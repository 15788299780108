<template>
  <div>
    <BCard title="Ações">
      <div>
        <b-form-checkbox @change="swtichAllowInactivate" v-model="allowInactivates" name="check-button" switch>
          Mostrar inativos?
        </b-form-checkbox>
      </div>
      <div
        :style="{
          overflowX: 'auto',
          width: '100%',
          display: 'flex',
          marginBottom: '20px',
          border: '1px solid',
          borderColor: '#d5d5d5',
          borderRadius: '4px',
          paddingLeft: '5px'
        }"
      >
        <div
          :style="{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center'
          }"
          v-for="user in users"
          :key="user.id"
        >
          <input type="checkbox" @change="(switchedToSelect = true), getPage(user.member_id)" checked />
          <span
            class="cutom-bullet"
            @click="handleClickBullet(user)"
            :style="{
              borderRadius: '50%',
              display: 'block',
              color: user.color,
              background: user.color,
              width: '15px',
              height: '15px'
            }"
          ></span>
          <div
            :style="{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center'
            }"
          >
            <p
              :style="{
                marginRight: '10px',
                marginTop: '12px'
              }"
            >
              {{ user.username }}
            </p>
          </div>
        </div>
      </div>

      <!-- SELECT User -->
    </BCard>

    <b-row class="mt-2">
      <b-col>
        <b-card title="Custos estimados e reais por tarefa & projeto">
          <div style="overflow-x: auto">
            <b-table
              responsive="sm"
              small
              id="my-table"
              hover
              striped
              :fields="fields"
              :items="items"
              :per-page="10"
              :current-page="0"
              :busy="isBusy"
              class="text-left"
              show-empty
            >
              <template #empty>
                <div class="p-2">
                  <b-alert show variant="primary">
                    <p class="text-center">
                      <br />
                      Não existe <strong>Tarefa</strong> dentro do escopo deste escritório
                    </p>
                    <hr />
                  </b-alert>
                </div>
              </template>
              <template v-slot:cell(title)="{ item }">
                <small>
                  <strong>{{ item.title ? item.title : item.task_title.task_title }}</strong></small
                >
                <br />
                <small> {{ item.notes ? 'Obs.: ' + item.notes : 'Sem descrição' }}</small>
              </template>
              <template v-slot:cell(project)="{ item }">
                <small
                  ><strong>#{{ item.project.project_code }}</strong></small
                >
                <p>{{ item.project.project_name }}</p>
              </template>
              <template v-slot:cell(actions)="props">
                <div @click="handleUpdateModal(props.item)">
                  <feather-icon v-b-tooltip.hover.lefttop.v-primary title="Editar fornecedor" icon="EditIcon" size="15" />Editar
                </div>
              </template>
              <template v-slot:cell(username)="{ item }">
                <div>
                  <div
                    :style="{
                      display: 'flex',
                      alignItems: 'center'
                    }"
                  >
                    <span
                      :style="{
                        borderRadius: '50%',
                        //   display: 'block',
                        background: item.member.color,
                        width: '10px',
                        height: '10px'
                      }"
                    >
                    </span>
                    <p
                      :style="{
                        height: 'auto',
                        paddingLeft: '5px',
                        paddingTop: '10px',
                        marginBottom: '0px',
                        fontSize: '12px'
                      }"
                    >
                      {{ item.member.access.user.username }}
                    </p>
                  </div>
                  <small>{{ item.member.access.user.email }}</small>
                </div>
              </template>

              <template v-slot:cell(date)="{ item }">
                <small>{{ dateFormatter(item.date, 'dd/MM/yyyy') }}</small>
              </template>
              <template v-slot:cell(start)="{ item }">
                <small> {{ item.start.slice(0, 5) }}</small>
              </template>
              <template v-slot:cell(end)="{ item }">
                <small>{{ item.end.slice(0, 5) }}</small>
              </template>

              <template v-slot:cell(estIndCost)="{ item }">
                <small>R$ {{numberToMonetary(item.estimateIndirectTaskCost) }}</small>
              </template>
              <template v-slot:cell(dirRealCost)="{ item }">
                <small>R$ {{numberToMonetary(item.dirRealCost) }}</small>
              </template>
              <template v-slot:cell(indRealCost)="{ item }">
                <small>R$ {{numberToMonetary(item.indRealCost) }}</small>
              </template>
              <template v-slot:cell(finalEstCost)="{ item }">
                <small>R$ {{numberToMonetary(item.estimateIndirectTaskCost + item.estimateDirectTaskCost) }}</small>
              </template>
              <template v-slot:cell(dirEstCost)="{ item }">
               <small> R$ {{ numberToMonetary(item.direct_cost) }}</small>
              </template>
              <template v-slot:cell(finalRealCost)="{ item }">
               <small> R$ {{ numberToMonetary(item.dirRealCost + item.indRealCost) }}</small>
              </template>
              <template v-slot:cell(duration)="{ item }">
                <small>{{ eventDuration(item.end, item.start) }}</small>
              </template>

              
            </b-table>
          </div>
          <b-pagination
            class="justify-content-center mt-1"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="getPage"
            aria-controls="my-table"
          ></b-pagination>
          <div class="mr-2 d-flex justify-content-end">
            <strong>Total de Tarefas: {{ totalRows }} </strong>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormCheckbox, BTable, BPagination, BAlert } from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';
import { differenceInMinutes } from 'date-fns';

export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BTable,
    BPagination,
    BAlert
  },

  data: () => ({
    moneyMask: {
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: false
    },
    member: {},
    users: [],
    list: false,
    dismount: true,
    eventSelect: {},
    timeSelect: '',
    dateSelect: '',
    items: [],
    idArr: [],
    events: [],
    allowInactivates: false,
    isBusy: false,
    loading: true,
    perPage: 10,
    rows: 0,
    totalRows: 0,
    memberCosts: [],
    switchedToSelect: false,
    fields: [
      {
        key: 'title',
        label: 'Tarefa',
        sortable: true,
        thStyle: { width: '20%' }
      },
      {
        key: 'project',
        label: 'Projeto',
        sortable: true,
        thStyle: { width: '20%' }
      },

      {
        key: 'username',
        label: 'Usuário',
        sortable: true
      },
      {
        key: 'date',
        label: 'Data',
        sortable: true
      },
      {
        key: 'start',
        label: 'Início',
        sortable: true
      },
      {
        key: 'end',
        label: 'Fim',
        sortable: true
      },
      {
        key: 'duration',
        label: 'Duração',
        sortable: false
      },
      {
        key: 'estIndCost',
        label: 'despesa fixa estimado',
        sortable: false
      },
      {
        key: 'dirEstCost',
        label: 'Custo direto estimado',
        sortable: false
      },
      {
        key: 'finalEstCost',
        label: 'Custo Final Estimado',
        sortable: false
      },
      {
        key: 'indRealCost',
        label: 'despesa fixa Real',
        sortable: false
      },
      {
        key: 'dirRealCost',
        label: 'Custo Direto Real',
        sortable: false
      },
      {
        key: 'finalRealCost',
        label: 'Custo Final Real',
        sortable: false
      }
    ]
  }),

  computed: {
    goodsFiltered() {
      return this.$store.getters.getGoodsFiltered;
    },
    response() {
      return this.$store.getters.getResponse;
    }
  },
  created() {
    this.getAllUsers();
    this.getTasks();
  },

  methods: {
    swtichAllowInactivate(e) {
      this.allowInactivates = e;
      this.idArr = [];
      this.events = [];
      this.items = [];
      this.getAllUsers();
      this.getTasks('');
    },

    async getAllUsers() {
      if (!this.userList) {
        this.$store
          .dispatch('getAllUsers', {
            workspace_id: this.$store.getters.currentWorkspace.id,
            inactive: this.allowInactivates,
            page: 1,
            perPage: 1000,
          })
          .then(resp => {
            if (resp) {
              //this.preset_data.users = resp;
              this.users = resp.data;
            }
            this.users.forEach(user => {
              this.idArr.push(user.member_id);
            });
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.getInactivesUsers();
      }
    },
    async getTasks(val) {
      this.currentPage = val ? val : 1;
      (this.isBusy = true),
        this.$store
          .dispatch('getAllProjectTasks', {
            workspace_id: this.$store.getters.currentWorkspace.id,
            inactive: this.allowInactivates,
            page: this.currentPage,
            perPage: 10
          })
          .then(resp => {
            this.events = [];

            console.log('res',resp)

            this.items = resp.data;
            this.totalRows = resp.pagination.total;

            this.items = this.items.map(item => {

              return {
                ...item,
                dirRealCost: item.real_direct_cost,
                estimateIndirectTaskCost: item.indirect_cost,
                estimateDirectTaskCost: item.direct_cost,
                indRealCost: item.real_indirect_cost
              };
            });

            this.items.map(dialog => {
              const originalDate = new Date(dialog.date);
              originalDate.setHours(originalDate.getHours() + 3);
              const formattedDate = originalDate.toISOString();

              this.events.push({
                ...dialog,
                color: dialog.member.color,
                project_code: dialog.project.project_code,
                project_name: dialog.project.project_name,
                date: formattedDate,
                startTime: dialog.start,
                endTime: dialog.end,
                name: dialog.title,
                comments: dialog.notes,
                username: dialog.member.access.user.username
              });
            });

            this.isBusy = false;
            if (resp === '') {
              this.items = [];
            }
            this.dismount = true;
          })
          .catch(err => {
            console.log(err);
          });
      this.loading = false;
    },
    async getTasksBySelect(member_id, val) {
      this.currentPage = val ? val : 1;
      this.idArr = this.idArr.includes(member_id) ? this.idArr.filter(id => id !== member_id) : [...this.idArr, member_id];

      this.$store
        .dispatch('getMyBySelect', {
          member_id: this.idArr,
          workspace_id: this.$store.getters.currentWorkspace.id,
          inactive: this.allowInactivates,
          type: 'select',
          page: this.currentPage,
          perPage: 10
        })
        .then(resp => {
          this.dismount = false;
          this.items = resp.data;
          this.events = [];
          if (resp === '') {
            this.items = [];
          }

          this.totalRows = resp.pagination.total;
          const totalEffectiveMemberHour = Math.round(
              this.response.usersWithCosts.reduce((total, user) => {
                return total + user.effectiveMemberHour;
              }, 0)
            );

            this.items = this.items.map(item => {
              const userCost = this.response.usersWithCosts.find(user => user.username === item.member.access.user.username);

              return {
                ...item,
                costHour: userCost ? userCost.value : 0,
                hourPerMonth: userCost ? userCost.hour_per_month : 0,
                officeMediumCost: this.response.totalProjectsCost + this.response.totalGoodsDepreciation,
                totalEffectiveMemberHour,

                effectiveOfficeHourCost:
                  (this.response.totalProjectsCost + this.response.totalGoodsDepreciation) / totalEffectiveMemberHour,

                indirectMemberCost:
                  ((this.response.totalProjectsCost + this.response.totalGoodsDepreciation) / totalEffectiveMemberHour) *
                  (userCost ? userCost.effectiveMemberHour : 0),

                estimateIndirectTaskCost:
                  (((((this.response.totalProjectsCost + this.response.totalGoodsDepreciation) / totalEffectiveMemberHour) *
                    (userCost ? userCost.effectiveMemberHour : 0)) /
                    userCost.effectiveMemberHour /
                    60) * this.eventDurationForCalc(item.end, item.start)).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              };
            });

          this.items.map(dialog => {
            this.events.push({
              ...dialog,
              project_code: dialog.project_code,
              date: dialog.date,
              startTime: this.formatTime(dialog.start),
              endTime: this.formatTime(dialog.end),
              name: dialog.title,
              comments: dialog.notes,
              username: dialog.username
            });
          });
          this.dismount = true;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getPage(member_id, val) {
      if (this.switchedToSelect) {
        this.getTasksBySelect(member_id, val);
      } else {
        this.getTasks(val);
      }
    },
    eventDuration(endTime, startTime) {
      const end = new Date(`01 january 2024 ${endTime} GMT-3`);
      const start = new Date(`01 january 2024 ${startTime} GMT-3`);

      const totalMinutes = differenceInMinutes(end, start);

      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    },
    eventDurationForCalc(endTime, startTime) {
      const end = new Date(`01 January 2024 ${endTime} GMT-3`);
      const start = new Date(`01 January 2024 ${startTime} GMT-3`);

      const totalMinutes = differenceInMinutes(end, start);

      const hoursWithFraction = totalMinutes / 60;

      return hoursWithFraction;
    }
  }
};
</script>
